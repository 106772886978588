<template>
  <div v-if="isReady">
    <div class="card card-custom card-stretch gutter-b">
      <!--begin::Header-->
      <div class="card-header border-0 pt-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark">
            {{ $t('sediment.station_list') }}
          </span>
        </h3>

        <div class="card-toolbar">
          <b-button-toolbar>
            <b-dropdown
              variant="primary"
              size="sm"
              class="mx-1"
              right
              split
              @click="$router.push({name: 'sediment.add_station'})"
              :text="$t('GENERAL.add')"
            >
              <b-dropdown-item variant="primary" @click="showImportModal()">Import</b-dropdown-item>
              <b-dropdown-item variant="primary" @click="downloadTemplate()">Template</b-dropdown-item>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-item variant="danger" @click="clearData()" v-if="total_post > 0">Clear</b-dropdown-item>
            </b-dropdown>
          </b-button-toolbar>
        </div>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body pt-3 pb-0">
        <!--begin::Table-->
        <div class="table-responsive">
          <table class="table table-borderless table-vertical-center" v-if="station_data">
            <thead>
              <tr>
                <th class="p-0" style="width: 15%">
                  {{ $t('sediment.station_code') }}
                </th>
                <th class="p-0" style="width: 50%">
                  {{ $t('sediment.station_name') }}
                </th>
                <th class="p-0" style="min-width: 10%">
                  {{ $t('GENERAL.last_update') }}
                </th>
                <th class="p-0 text-right" style="min-width: 10%">
                  {{ $t('GENERAL.option') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(item, i) in station_data">
                <tr v-bind:key="i">
                  <td class="pl-0">
                    <a class="text-dark-75">
                      {{ item.code }}
                    </a>
                  </td>
                  <td class="pl-0">
                    <a class="text-dark-75">
                      {{ item.name ? item.name : '-' | str_limit(128) }}
                    </a>
                  </td>
                  <td class="pl-0">
                    <div>
                      <a class="text-muted font-weight-bold">
                        {{ item.lu_datetime }}
                      </a>
                    </div>
                  </td>
                  <td class="text-right pr-0">
                    <router-link
                      :to="{
                        name: 'sediment.edit_station',
                        params: {id: item.id},
                      }"
                      class="btn btn-icon btn-light btn-sm mx-3"
                    >
                      <span class="svg-icon svg-icon-md svg-icon-primary">
                        <!--begin::Svg Icon-->
                        <inline-svg src="media/svg/icons/Communication/Write.svg"></inline-svg>
                        <!--end::Svg Icon-->
                      </span>
                    </router-link>
                    <a class="btn btn-icon btn-light btn-sm" @click="deleteConfirmationModal(item.id)" ref="btnShow">
                      <span class="svg-icon svg-icon-md svg-icon-danger">
                        <!--begin::Svg Icon-->
                        <inline-svg src="media/svg/icons/General/Trash.svg"></inline-svg>
                        <!--end::Svg Icon-->
                      </span>
                    </a>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
          <div class="text-center" v-else>No Data</div>
        </div>
        <!--end::Table-->
      </div>
      <!--end::Body-->
      <!--end::Advance Table Widget 2-->
    </div>
    <stationImportComponent ref="stationImportComp" type="sediment" />
  </div>

  <div class="card card-custom card-stretch gutter-b" v-else>
    <!--begin::Body-->
    <div class="card-body pt-3 p-20">
      <div class="d-flex justify-content-center mb-3">
        <b-spinner label="Loading..."></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module';
import * as Services from '../services.js';
//Component
import stationImportComponent from '../components/import_station';

export default {
  name: 'sediment_station',
  components: {
    stationImportComponent,
  },
  data() {
    return {
      isReady: false,
      station_data: null,
      total_post: 0,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: 'Sediment Data', route: '/admin/sediment'}, {title: 'Station'}]);

    this.getData();
  },
  methods: {
    getData() {
      this.$store
        .dispatch(Services.GET_STATION_LIST)
        .then((res) => {
          this.isReady = true;
          this.station_data = res.data;
          this.total_post = res.data.length;
        })
        .catch((error) => {
          this.isReady = true;
          console.log(error);
        });
    },
    showImportModal() {
      this.$refs.stationImportComp.showModal();
    },
    clearData() {
      this.deleteConfirm(() => {
        this.isSaving = true;
        this.$store
          .dispatch(Services.CLEAR_STATION_DATA)
          .then((res) => {
            this.getData();
            this.isSaving = false;
          })
          .catch((error) => {
            // console.log(error);
            this.isSaving = false;
          });
      });
    },
    downloadTemplate() {
      this.$store
        .dispatch(Services.GET_STATION_TEMPLATE_FILE)
        .then((res) => {
          const blob = new Blob([res], {type: 'text/csv'});
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = 'station_template';
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteConfirmationModal(id) {
      this.boxTwo = '';
      this.$bvModal
        .msgBoxConfirm('Are you sure to delete this?', {
          title: 'Please Confirm',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Confirm',
          cancelTitle: 'Cancel',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.isReady = false;
            // console.log('Deleting post confirmation: ' + id);
            this.$store
              .dispatch(Services.DELETE_STATION, {id: id})
              // go to which page after successfully login
              .then((res) => {
                this.$store
                  .dispatch(Services.GET_STATION_LIST)
                  // go to which page after successfully login
                  .then((res) => {
                    this.isReady = true;
                    this.station_data = res.data;
                    this.total_post = res.data.length;
                  })
                  .catch((error) => {
                    // console.log(error);
                  });
              })
              .catch((error) => {
                // console.log(error);
                // Swal.fire({
                //     title: '',
                //     text: error,
                //     icon: 'error',
                //     confirmButtonClass: 'btn btn-secondary',
                //     heightAuto: false,
                // });
              });
          }
        })
        .catch((err) => {});
    },
  },
};
</script>

<style></style>
